<template>
  <div class="lcf-repay-plan">
    <NavBar
      title="还款计划"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'AssetsDetail', params: { assets_id: id }})"
      placeholder
    ></NavBar>

    <div class="lcf-repay-plan-head van-hairline--bottom">
      <div>还款日期</div>
      <div>本金（元）</div>
      <div>利息（元）</div>
      <div>状态</div>
    </div>
    <div class="lcf-repay-plan-body">
      <div v-for="(v, k) in repays" :key="k" class="lcf-repay-plan-body-item">
        <div>{{ v.expect_date }}</div>
        <div>{{ v.expect_capital }}</div>
        <div>{{ v.expect_interest }}</div>
        <div>{{ v.status }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Notify } from 'vant'
import { mapActions } from 'vuex'

export default {
  props: ['id'],
  components: {
    NavBar,
  },
  data() {
    return {
      repays: [],
    }
  },
  mounted() {
    this.showRepayPlan()

    this.$once('hook:beforeDestroy', function () {
      this.repays = []
    })
  },
  methods: {
    ...mapActions(['requestMyAssetsRepays']),
    async showRepayPlan() {
      let error, data
      ;[error, data] = await to(this.requestMyAssetsRepays(this.id))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
      }

      if (data) {
        this.repays = data
      }

      return data
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-repay-plan {
  .lcf-repay-plan-head {
    display: flex;
    flex-flow: row nowrap;
    height: 54px;
    position: fixed;
    width: 100%;
    background: #fff;
    div {
      flex: 1 0;
      text-align: center;
      line-height: 54px;
      font-size: 12px;
      color: $lcf-color-text-gray;
    }
  }
  .lcf-repay-plan-body {
    margin: 54px 0 0;
    .lcf-repay-plan-body-item {
      display: flex;
      flex-flow: row nowrap;
      height: 54px;
      div {
        flex: 1 0;
        text-align: center;
        line-height: 54px;
        font-size: 14px;
      }
    }
  }
}
</style>
